import type { Navigation } from '~/types/base'

const navigationList: Array<Navigation> = [
  {
    name: 'product-lines',
    routeName: 'contact',
    title: 'Produktreihen',
    submenu: [
      {
        name: '@todo',
        routeName: 'contact',
        title: 'Wendeplatten-Werkzeuge',
        subheadline: '@todo',
        thumbnail: '@todo',
      },
      {
        name: '@todo',
        routeName: 'contact',
        title: 'Vollhartmetall-Werkzeuge',
        subheadline: '@todo',
        thumbnail: '@todo',
      },
      {
        name: '@todo',
        routeName: 'contact',
        title: 'Aufnahmesysteme / Schrumpfgeräte',
        subheadline: '@todo',
        thumbnail: '@todo',
      },
      {
        name: '@todo',
        routeName: 'contact',
        title: 'Spindelsysteme',
        subheadline: '@todo',
        thumbnail: '@todo',
      },
      {
        name: '@todo',
        routeName: 'contact',
        title: 'Graviersysteme (Gravfix®)',
        subheadline: '@todo',
        thumbnail: '@todo',
      },
      {
        name: '@todo',
        routeName: 'contact',
        title: 'Oberflächenveredelung (Forgefix®)',
        subheadline: '@todo',
        thumbnail: '@todo',
      },
      {
        name: '@todo',
        routeName: 'contact',
        title: 'Wendeschneidplatten',
        subheadline: '@todo',
        thumbnail: '@todo',
      },
    ],
  },
  {
    name: 'industry-sectors',
    routeName: 'sectors',
    title: 'Branchen',
  },
  {
    name: 'materials',
    routeName: 'materials',
    title: 'Werkstoffe',
    submenu: [
      {
        name: '@todo',
        routeName: 'contact',
        title: 'Stahl',
        subheadline: '@todo',
        thumbnail: '@todo',
      },
      {
        name: '@todo',
        routeName: 'contact',
        title: 'Hochwarmfeste Legierungen',
        subheadline: '@todo',
        thumbnail: '@todo',
      },
      {
        name: '@todo',
        routeName: 'contact',
        title: 'NE-Metalle',
        subheadline: '@todo',
        thumbnail: '@todo',
      },
      {
        name: '@todo',
        routeName: 'contact',
        title: 'Nichtrostender Stahl',
        subheadline: '@todo',
        thumbnail: '@todo',
      },
      {
        name: '@todo',
        routeName: 'contact',
        title: 'Eisenguss',
        subheadline: '@todo',
        thumbnail: '@todo',
      },
      {
        name: '@todo',
        routeName: 'contact',
        title: 'Gehärtete Werkstoffe',
        subheadline: '@todo',
        thumbnail: '@todo',
      },
    ],
  },
  {
    name: 'about-us',
    routeName: 'about-pokolm',
    title: 'Das ist pokolm',
  },
  {
    name: 'specific-solutions',
    routeName: 'contact',
    title: 'Speziallösungen',
  },
  {
    name: 'news',
    routeName: 'news',
    title: 'News',
  },
  {
    name: 'events',
    routeName: 'contact',
    title: 'Events',
  },
  {
    name: 'career',
    routeName: 'career',
    title: 'Karriere',
    submenu: [
      {
        name: 'training',
        routeName: 'training/',
        title: 'navigation.training',
        subheadline:
          'Zweizeiler mit Zusammenfassung dessen, was hier zu erwarten ist.',
        thumbnail: '/img/elements/career-teaser-training.webp',
      },
      {
        name: 'jobs',
        routeName: 'jobs/',
        title: 'navigation.jobs',
        subheadline:
          'Zweizeiler mit Zusammenfassung dessen, was hier zu erwarten ist.',
        thumbnail: '/img/elements/career-teaser-job.webp',
      },
      {
        name: 'internship',
        routeName: 'training/',
        title: 'navigation.internship',
        subheadline:
          'Zweizeiler mit Zusammenfassung dessen, was hier zu erwarten ist.',
        thumbnail: '/img/elements/career-teaser-internship.webp',
      },
    ],
  },
  {
    name: 'contact',
    routeName: 'contact',
    title: 'Kontakt',
  },
]

export default navigationList
